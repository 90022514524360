// Dependencies
import React from "react";

const Template = () => {
  // Nothing needed...
  return (
    <span />
  )
}

export default Template
